import React from "react"
import { graphql } from "gatsby"
import BlogPostPreviewGrid from "../components/main/blog-post-preview-grid"
import GraphQLErrorList from "../components/main/graphql-error-list"
import SEO from "../components/main/seo"
import Layout from "../containers/layout"
import { mapEdgesToNodes } from "../lib/helpers"
import { responsiveTitle1 } from "../styles/typography.module.css"

export const query = graphql`
  query TagPageQuery($id: String!) {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { category: { id: { glob: $id } } }
    ) {
      edges {
        node {
          parenttag {
            slug {
              current
            }
          }

          id
          publishedAt
          category {
            title
            catSlug
            favoriteColor {
              hex
            }
          }
          articleTier
          _rawExcerpt
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
            alt
            attribution
            description
          }
          title
          slug {
            current
          }
          _rawAuthors(resolveReferences: { maxDepth: 2 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          authors {
            _key

            image {
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
              }
            }
            name
          }
        }
      }
    }
  }
`

const BlogPage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  return (
    <Layout>
      <SEO title="Themengebiet" />

      <h1 className={responsiveTitle1}>{props.pageContext.categorytitle}</h1>
      {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid nodes={postNodes} />}
    </Layout>
  )
}

export default BlogPage
